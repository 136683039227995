// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_tt d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_tv d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_tw d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_tx d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_ty d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_tz d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_tB d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rN p_rN";
export var heroExceptionNormal = "t_rP p_rP";
export var heroExceptionLarge = "t_rQ p_rQ";
export var Title1Small = "t_rp p_rp p_qV p_qW";
export var Title1Normal = "t_rq p_rq p_qV p_qX";
export var Title1Large = "t_rr p_rr p_qV p_qY";
export var BodySmall = "t_rD p_rD p_qV p_rc";
export var BodyNormal = "t_rF p_rF p_qV p_rd";
export var BodyLarge = "t_rG p_rG p_qV p_rf";