// extracted by mini-css-extract-plugin
export var carouselContainer = "H_v7 d_w d_H d_bf d_Z";
export var carouselContainerCards = "H_v8 H_v7 d_w d_H d_bf d_Z";
export var carouselContainerSides = "H_v9 d_w d_H d_Z";
export var prevCarouselItem = "H_wb d_w d_H d_0 d_k";
export var prevCarouselItemL = "H_wc H_wb d_w d_H d_0 d_k";
export var moveInFromLeft = "H_wd";
export var prevCarouselItemR = "H_wf H_wb d_w d_H d_0 d_k";
export var moveInFromRight = "H_wg";
export var selectedCarouselItem = "H_wh d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "H_wj H_wh d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "H_wk H_wh d_w d_H d_Z d_bf";
export var nextCarouselItem = "H_wl d_w d_H d_0 d_k";
export var nextCarouselItemL = "H_wm H_wl d_w d_H d_0 d_k";
export var nextCarouselItemR = "H_wn H_wl d_w d_H d_0 d_k";
export var arrowContainer = "H_wp d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "H_wq H_wp d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "H_wr H_wq H_wp d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "H_ws H_wp d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "H_wt";
export var nextArrowContainerHidden = "H_wv H_ws H_wp d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "H_kG d_0";
export var prevArrow = "H_ww H_kG d_0";
export var nextArrow = "H_wx H_kG d_0";
export var carouselIndicatorContainer = "H_wy d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "H_wz d_w d_bz d_bF";
export var carouselText = "H_wB d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "H_wC H_wB d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "H_wD d_b7";
export var carouselIndicator = "H_wF H_wD d_b7";
export var carouselIndicatorSelected = "H_wG H_wD d_b7";
export var arrowsContainerTopRight = "H_wH d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "H_wJ d_0 d_bl d_bC";
export var arrowsContainerSides = "H_wK d_0 d_bl d_bC";
export var smallArrowsBase = "H_wL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "H_wM H_wL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "H_wN H_wM H_wL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "H_wP H_wL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "H_wQ H_wP H_wL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "H_p9";
export var iconSmall = "H_wR";
export var multipleWrapper = "H_wS d_bC d_bF d_bf";
export var multipleImage = "H_wT d_bC";
export var sidesPrevContainer = "H_wV H_wM H_wL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "H_wW H_wM H_wL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";