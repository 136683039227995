// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "z_vg d_gS d_cw d_dv";
export var quoteParagraphCenter = "z_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "z_gV d_gV d_cw d_dx";
export var quoteRowLeft = "z_vh d_bG";
export var quoteRowCenter = "z_vj d_bD";
export var quoteRowRight = "z_vk d_bH";
export var quoteWrapper = "z_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "z_gR d_gR";
export var quoteExceptionSmall = "z_rV p_rV";
export var quoteExceptionNormal = "z_rW p_rW";
export var quoteExceptionLarge = "z_rX p_rX";
export var quoteAuthorExceptionSmall = "z_rY p_rY";
export var quoteAuthorExceptionNormal = "z_rZ p_rZ";
export var quoteAuthorExceptionLarge = "z_r0 p_r0";