// extracted by mini-css-extract-plugin
export var alignLeft = "v_qj d_bG";
export var alignCenter = "v_bP d_bD";
export var alignRight = "v_qm d_bH";
export var textAlignLeft = "v_tC";
export var textAlignCenter = "v_tD";
export var textAlignRight = "v_tF";
export var hoursInnerWrapperAlt = "v_tG d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "v_tH d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "v_tJ d_dw";
export var titleMargin = "v_tK d_cw";
export var hoursInnerInnerWrapper = "v_tL d_cz";