// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "C_vz d_bD";
export var storyRowWrapper = "C_hx d_hx d_bK";
export var storyLeftWrapper = "C_vB d_bz d_bP";
export var storyWrapperFull = "C_vC d_cD";
export var storyWrapperFullLeft = "C_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "C_mv d_hy";
export var storyLeftWrapperCenter = "C_vD d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "C_vF d_hF";
export var storyHeader = "C_vG d_hD d_w d_cs";
export var storyHeaderCenter = "C_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "C_hB d_hB d_by d_dw";
export var storyBtnWrapper = "C_vH d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "C_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "C_vJ d_fg d_Z";
export var imageWrapperFull = "C_vK d_w d_H d_bf d_Z";
export var SubtitleSmall = "C_qd p_qd p_qV p_q5";
export var SubtitleNormal = "C_qf p_qf p_qV p_q6";
export var SubtitleLarge = "C_qg p_qg p_qV p_q7";
export var textLeft = "C_dv";
export var textCenter = "C_dw";
export var textRight = "C_dx";