// extracted by mini-css-extract-plugin
export var lbContainerOuter = "F_vS";
export var lbContainerInner = "F_vT";
export var movingForwards = "F_vV";
export var movingForwardsOther = "F_vW";
export var movingBackwards = "F_vX";
export var movingBackwardsOther = "F_vY";
export var lbImage = "F_vZ";
export var lbOtherImage = "F_v0";
export var prevButton = "F_v1";
export var nextButton = "F_v2";
export var closing = "F_v3";
export var appear = "F_v4";