// extracted by mini-css-extract-plugin
export var socialContainer = "B_vl";
export var instagramLink = "B_vm";
export var socialWrapperLeft = "B_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "B_vn";
export var socialContentContainerFull = "B_vp";
export var instagramContainer = "B_vq";
export var twitterContainer = "B_vr";
export var facebookContainer = "B_vs";
export var socialErrorContainer = "B_vt";
export var facebookContainerWide = "B_vv";
export var twitterContainerWide = "B_vw";
export var socialParagraphCenter = "B_vx";
export var instaWrapper = "B_vy";
export var SubtitleSmall = "B_qd p_qd p_qV p_q5";
export var SubtitleNormal = "B_qf p_qf p_qV p_q6";
export var SubtitleLarge = "B_qg p_qg p_qV p_q7";
export var textLeft = "B_dv";
export var textCenter = "B_dw";
export var textRight = "B_dx";